/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const GuideMe: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path d='M19 3L19.3089 3.61779C19.4416 3.88328 19.508 4.01603 19.5967 4.13106C19.6754 4.23313 19.7669 4.32464 19.8689 4.40332C19.984 4.49199 20.1167 4.55836 20.3822 4.6911L21 5L20.3822 5.3089C20.1167 5.44164 19.984 5.50801 19.8689 5.59668C19.7669 5.67536 19.6754 5.76687 19.5967 5.86894C19.508 5.98397 19.4416 6.11672 19.3089 6.38221L19 7L18.6911 6.38221C18.5584 6.11672 18.492 5.98397 18.4033 5.86894C18.3246 5.76687 18.2331 5.67536 18.1311 5.59668C18.016 5.50801 17.8833 5.44164 17.6178 5.3089L17 5L17.6178 4.6911C17.8833 4.55836 18.016 4.49199 18.1311 4.40332C18.2331 4.32464 18.3246 4.23313 18.4033 4.13106C18.492 4.01603 18.5584 3.88328 18.6911 3.61779L19 3Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M5 17L5.3089 17.6178C5.44164 17.8833 5.50801 18.016 5.59668 18.1311C5.67536 18.2331 5.76687 18.3246 5.86894 18.4033C5.98397 18.492 6.11672 18.5584 6.38221 18.6911L7 19L6.38221 19.3089C6.11672 19.4416 5.98397 19.508 5.86894 19.5967C5.76687 19.6754 5.67536 19.7669 5.59668 19.8689C5.50801 19.984 5.44164 20.1167 5.3089 20.3822L5 21L4.6911 20.3822C4.55836 20.1167 4.49199 19.984 4.40332 19.8689C4.32464 19.7669 4.23313 19.6754 4.13106 19.5967C4.01603 19.508 3.88328 19.4416 3.61779 19.3089L3 19L3.61779 18.6911C3.88328 18.5584 4.01603 18.492 4.13106 18.4033C4.23313 18.3246 4.32464 18.2331 4.40332 18.1311C4.49199 18.016 4.55836 17.8833 4.6911 17.6178L5 17Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
            <path fillRule='evenodd' clipRule='evenodd' d='M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M10 16C10.5523 16 11 16.4477 11 17L11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19L13 17C13 16.4477 13.4477 16 14 16C14.5523 16 15 16.4477 15 17L15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19L9 17C9 16.4477 9.44772 16 10 16Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M12 2C12.5523 2 13 2.44772 13 3L13 4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4L11 3C11 2.44772 11.4477 2 12 2Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M20.6602 17C20.3841 17.4783 19.7725 17.6422 19.2942 17.366L18.4282 16.866C17.9499 16.5899 17.786 15.9783 18.0621 15.5C18.3383 15.0217 18.9499 14.8578 19.4282 15.134L20.2942 15.634C20.7725 15.9101 20.9364 16.5217 20.6602 17Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M21.6592 9.41182C21.8022 9.94528 21.4856 10.4936 20.9521 10.6366L19.9862 10.8954C19.4527 11.0383 18.9044 10.7217 18.7614 10.1883C18.6185 9.65481 18.9351 9.10647 19.4685 8.96353L20.4345 8.70471C20.9679 8.56177 21.5163 8.87835 21.6592 9.41182Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M5.93781 8.5C5.66166 8.97829 5.05007 9.14217 4.57178 8.86602L3.70575 8.36602C3.22746 8.08988 3.06359 7.47829 3.33973 7C3.61587 6.5217 4.22746 6.35783 4.70575 6.63397L5.57178 7.13397C6.05007 7.41011 6.21395 8.0217 5.93781 8.5Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M5.23849 13.8117C5.38144 14.3452 5.06485 14.8935 4.53139 15.0365L3.56546 15.2953C3.032 15.4382 2.48366 15.1217 2.34072 14.5882C2.19778 14.0547 2.51436 13.5064 3.04782 13.3634L4.01375 13.1046C4.54722 12.9617 5.09555 13.2783 5.23849 13.8117Z' fill='currentColor' />
        </svg>;
};

export default GuideMe;